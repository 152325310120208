import { ArrowBackIos } from "@mui/icons-material";

const RotatedArrowIcon = ({ open }: { open: boolean }) => {
  return (
    <ArrowBackIos
      sx={{
        transform: open ? "rotate(90deg)" : "rotate(-90deg)",
        transition: "transform 0.3s ease",
        fontSize: "16px",
        position: "relative",
        top: open ? "4px" : "-4px",
        right: "7px",
      }}
    />
  );
};

export default RotatedArrowIcon;
