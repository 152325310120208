import { useEffect, useCallback } from "react";

type KeyNavigationOptions = {
  selected: number | undefined;
  setSelected: (index: number) => void;
  valueRef: React.MutableRefObject<number> | any;
  onEnter?: () => void;
};

export const useKeyNavigation = ({
  selected,
  setSelected,
  valueRef,
  onEnter,
}: KeyNavigationOptions) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        event.preventDefault();
        const selectedIndex = selected && selected < 3 ? selected + 1 : 1;
        setSelected(selectedIndex);
        valueRef.current = selectedIndex;
      } else if (event.key === "Enter") {
        if (onEnter && selected) {
          onEnter();
        }
      }
    },
    [selected, setSelected, valueRef, onEnter]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
};
