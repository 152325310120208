import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { MenuItem, Select, Radio } from "@mui/material";

import { BASE_SIZES } from "../../../../constants/modal";
import { font } from "../../../../configs";

import RotatedArrowIcon from "../RotatedArrowIcon";

import "./index.css";

interface IPropsStyledSelector {
  sizeCountry: string;
  setSizeCountry: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledSelector: FC<IPropsStyledSelector> = ({
  sizeCountry,
  setSizeCountry,
  open,
  setOpen,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Select
      value={sizeCountry}
      onChange={(event) => setSizeCountry(event.target.value)}
      open={open}
      onClose={() => setOpen(false)}
      className="breasts__select"
      IconComponent={(props) => <RotatedArrowIcon open={open} {...props} />}
      renderValue={(selected) => (
        <span
          className={`flag-icon ${selected}`}
          style={{
            display: "inline-block",
            width: "24px",
            height: "16px",
            backgroundSize: "cover",
          }}
        />
      )}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: 0,
            fontFamily: isMobile
              ? `${font}, sans-serif`
              : `${font}, sans-serif !important`,
            boxShadow: "none",
            "& .MuiMenuItem-root": {
              display: "flex",
              alignItems: "center",
              padding: "10px 20px",
              gap: "10px",
            },
            "& .Mui-selected": {
              backgroundColor: "transparent",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#f5f5f5",
            },
          },
        },
        PaperProps: {
          sx: {
            border: "1px solid #ddd",
            borderRadius: "4px",
            boxShadow: "none",
            width: "300px",
            marginTop: "5px",
            "& .Mui-selected": {
              backgroundColor: "transparent",
            },
          },
        },
      }}
      sx={{
        backgroundColor: "#FFFFFF",
        fontFamily: isMobile
          ? `${font}, sans-serif`
          : `${font}, sans-serif !important`,
        border: "1px solid #ddd",
        borderRadius: "4px",
        padding: "6px 4px 6px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "auto",
        position: "relative",
        left: "10px",
      }}
    >
      {BASE_SIZES?.map(({ name, label, image }) => (
        <MenuItem key={name} value={image}>
          <span
            className={`flag-icon ${image}`}
            style={{
              display: "inline-block",
              width: "24px",
              height: "16px",
              backgroundSize: "cover",
            }}
          />
          {label}
          <Radio
            checked={sizeCountry === image}
            value={image}
            sx={{
              color: "black",
              "&.Mui-checked": {
                color: "black",
              },
            }}
            style={{
              marginLeft: "auto",
              position: "relative",
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default StyledSelector;
