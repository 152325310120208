import React, { FC, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import ArrowIcon from "../../../../assets/icons/ArrowIcon";

import { resultScreenUI } from "../../../../configs";

import "./index.css";

interface IPropsUnavailableGallery {
  similarProducts: Array<{ image_url: string; title: string }>;
}

const UnavailableGallery: FC<IPropsUnavailableGallery> = ({
  similarProducts,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isLargeHeightRelative = useMediaQuery({
    maxHeight: 778,
    minWidth: 767,
  });

  const { t } = useTranslation("components/results/result");

  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftX, setScrollLeftX] = useState(0);
  const [draggingThresholdExceeded, setDraggingThresholdExceeded] =
    useState(false);

  // const [arrowPositionHandle, setArrowPositionHandle] = useState(0);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);

  const scrollByItem = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const itemWidth =
        scrollRef.current.querySelector(".info")?.clientWidth || 160;
      const gap = 10;
      const totalScrollAmount = itemWidth + gap;
      const newScrollPosition =
        scrollRef.current.scrollLeft +
        (direction === "right" ? totalScrollAmount : -totalScrollAmount);

      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });

      setIsLeftArrowVisible(newScrollPosition > 0);
      setIsRightArrowVisible(
        newScrollPosition + scrollRef.current.clientWidth <
          scrollRef.current.scrollWidth
      );
    }
  };

  const extractIdFromUrl = (url: string) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  };

  const handleClick = (item: any, index: number) => {
    const id = extractIdFromUrl(item.image_url);

    // handleAnalytics("action", "similar_product", {
    //   key: "variant_selected",
    //   type: "INT",
    //   value: index + 1,
    // });

    // if (id) {
    //   handleAnalytics("action", "similar_product", {
    //     key: "variant_id",
    //     type: "STR",
    //     value: id,
    //   });
    // }

    if (!draggingThresholdExceeded) {
      if (item.product_url) {
        window.open(item.product_url, "_blank");
      }
    }
    setDraggingThresholdExceeded(false); // Reset after click
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeftX(scrollRef.current.scrollLeft);
      setDraggingThresholdExceeded(false); // Reset threshold on new drag
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !scrollRef.current) return;

    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeftX - walk;

    setIsLeftArrowVisible(scrollRef.current.scrollLeft > 0);
    setIsRightArrowVisible(
      scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
        scrollRef.current.scrollWidth
    );
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.touches[0].pageX - scrollRef.current.offsetLeft);
      setScrollLeftX(scrollRef.current.scrollLeft);
      setDraggingThresholdExceeded(false);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging || !scrollRef.current) return;

    const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;

    if (Math.abs(walk) > 5) {
      setDraggingThresholdExceeded(true);
    }

    scrollRef.current.scrollLeft = scrollLeftX - walk;

    setIsLeftArrowVisible(scrollRef.current.scrollLeft > 0);
    setIsRightArrowVisible(
      scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
        scrollRef.current.scrollWidth
    );
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const productTitle =
    (resultScreenUI.similarProducts as any)?.productTitle || {};

  const productTitleStyles: React.CSSProperties = {
    color: productTitle.fontColor as React.CSSProperties["color"],
    fontSize: productTitle.fontSize as React.CSSProperties["fontSize"],
    fontWeight:
      (productTitle.fontWeight as React.CSSProperties["fontWeight"]) ||
      "normal",
    maxWidth: isLargeHeightRelative ? "120px" : isMobile ? "100px" : "160px",
  };

  return (
    <div className="gallery-container">
      <p
        className="title"
        style={{
          color: resultScreenUI.similarProducts.title
            .fontColor as React.CSSProperties["color"],
          fontSize: resultScreenUI.similarProducts.title
            .fontSize as React.CSSProperties["fontSize"],
          fontWeight: resultScreenUI.similarProducts.title
            .fontWeight as React.CSSProperties["fontWeight"],
          textAlign: resultScreenUI.similarProducts.title
            .textAlign as React.CSSProperties["textAlign"],
        }}
      >
        {t("unavailable.title")}
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isLeftArrowVisible ? (
          <div onClick={() => scrollByItem("left")} className="left-arrow">
            <ArrowIcon />
          </div>
        ) : null}
        <div
          className="scroll-container"
          ref={scrollRef}
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            display: "inline-flex",
            marginBottom: isMobile ? "30px" : 0,
            cursor: isDragging ? "grabbing" : "grab",
            width: isLargeHeightRelative ? "auto" : "600px",
          }}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {similarProducts?.map((item, index) => (
            <div
              key={index}
              className="info"
              style={{
                minWidth: isLargeHeightRelative
                  ? "120px"
                  : isMobile
                  ? "100px"
                  : "160px",
                flex: "0 0 auto",
              }}
              onClick={() => handleClick(item, index)}
            >
              <div
                className="img-container"
                style={{
                  width: isLargeHeightRelative
                    ? "120px"
                    : isMobile
                    ? "100px"
                    : "160px",
                  height: isLargeHeightRelative
                    ? "115px"
                    : isMobile
                    ? "100px"
                    : "175px",
                  background: `url(${item.image_url}) no-repeat center center`,
                  backgroundSize: "cover",
                  pointerEvents: "none",
                }}
              />
              <p
                className="info-text"
                style={productTitle ? productTitleStyles : undefined}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
        {isRightArrowVisible ? (
          <div onClick={() => scrollByItem("right")} className="right-arrow">
            <ArrowIcon style={{ transform: "rotate(180deg)" }} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UnavailableGallery;
