import combinedConfig from "./combinedConfig.json";

export const brandsArray = [
  {
    name: "Lacoste",
    domains: ["lacoste.com", "shop-fr-staging.lacoste.com"],
    config: combinedConfig.Lacoste.config,
  },
  {
    name: "KENZO",
    domains: ["www.kenzo.com", "stg.kenzo.com", "sb03.kenzo.com"],
    config: combinedConfig.KENZO.config,
  },
  {
    name: "Sporty & Rich",
    domains: ["sportyandrich.com"],
    config: combinedConfig["Sporty & Rich"].config,
  },
  {
    name: "Place des Tendances",
    domains: ["placedestendances.com"],
    config: combinedConfig["Place des Tendances"].config,
  },
  {
    name: "Le Slip Français",
    domains: ["www.leslipfrancais.fr"],
    config: combinedConfig["Le Slip Français"].config,
  },
  {
    name: "IZAC",
    domains: ["izac.fr", "izac-paris.com"],
    config: combinedConfig.IZAC.config,
  },
  {
    name: "JOTT",
    domains: ["jott.com", "justoverthetop-testing.myshopify.com"],
    config: combinedConfig.JOTT.config,
  },
  {
    name: "From Future",
    domains: ["www.fromfuture.com", "fromfuture-int.com"],
    config: combinedConfig["From Future"].config,
  },
  {
    name: "Circle SportsWear",
    domains: ["circlesportswear.com"],
    config: combinedConfig["Circle SportsWear"].config,
  },
  {
    name: "Rodier",
    domains: ["www.rodier.fr", "preprod02-fo-rodier.wshop.cloud"],
    config: combinedConfig.Rodier.config,
  },
  {
    name: "La Canadienne",
    domains: ["la-canadienne.com"],
    config: combinedConfig["La Canadienne"].config,
  },
  {
    name: "Gerard Darel",
    domains: ["gerarddarel.com"],
    config: combinedConfig["Gerard Darel"].config,
  },
  {
    name: "Ron Dorff",
    domains: ["rondorff.com", "uk.rondorff.com", "us.rondorff.com"],
    config: combinedConfig["Ron Dorff"].config,
  },
  {
    name: "The Kooples",
    domains: [
      "thekooples.com",
      "dev.thekooples.com",
      "stg.thekooples.com",
      "staging.thekooples.com",
      "www.thekooples.com",
      "getkleep-marcy-paris.myshopify.com",
    ],
    config: combinedConfig["The Kooples"].config,
  },
  {
    name: "Chlore",
    domains: ["www.chlore-swimwear.fr"],
    config: combinedConfig.Chlore.config,
  },
  {
    name: "Molli",
    domains: ["www.molli.com", "molli.com"],
    config: combinedConfig.Molli.config,
  },
  {
    name: "Place du Jour",
    domains: ["placedujour.fr"],
    config: combinedConfig["Place du Jour"].config,
  },
  {
    name: "Gualap",
    domains: ["gualap.com"],
    config: combinedConfig.Gualap.config,
  },
  {
    name: "Vogstore",
    domains: ["vogstoree.com"],
    config: combinedConfig.Vogstore.config,
  },
  {
    name: "SRP",
    domains: ["showroomprive.com", "fr-v4.terone.showroomprive.net"],
    config: combinedConfig.SRP.config,
  },
  {
    name: "Hartford",
    domains: ["hartford.fr"],
    config: combinedConfig.Hartford.config,
  },
  {
    name: "La Petite Etoile",
    domains: ["www.lapetiteetoile.com"],
    config: combinedConfig["La Petite Etoile"].config,
  },
  {
    name: "Mister K",
    domains: ["misterk.fr"],
    config: combinedConfig["Mister K"].config,
  },
  {
    name: "Petrone",
    domains: ["petroneparis.fr"],
    config: combinedConfig.Petrone.config,
  },
  {
    name: "Zadig & Voltaire",
    domains: ["zadig-et-voltaire.com"],
    config: combinedConfig["Zadig & Voltaire"].config,
  },
  {
    name: "Bensimon",
    domains: ["bensimon.com", "preprod-fo.bensimon.wshop.cloud"],
    config: combinedConfig["Bensimon"].config,
  },
  {
    name: "Stella & Suzie",
    domains: ["stellaetsuzie.com"],
    config: combinedConfig["Stella & Suzie"].config,
  },
  {
    name: "Antonelle",
    domains: ["www.antonelle.fr"],
    config: combinedConfig["Antonelle"].config,
  },
  {
    name: "Une Jour Ailleurs",
    domains: ["www.unjourailleurs.com"],
    config: combinedConfig["Une Jour Ailleurs"].config,
  },
  {
    name: "Kookai",
    domains: ["kookai.fr"],
    config: combinedConfig["Kookai"].config,
  },
  {
    name: "Asphalte",
    domains: ["www.asphalte.com"],
    config: combinedConfig["Asphalte"].config,
  },
];

// Function to extract query parameter from the URL
export const getQueryParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

// Function to get the config based on domain
export const getConfig = () => {
  const domain = getQueryParam("domain") || brandsArray?.[0]?.domains?.[0];
  const brand = brandsArray.find((brand) => brand.domains.includes(domain));

  if (brand) {
    return brand.config;
  } else {
    return brandsArray?.[0]?.config;
  }
};
