import { FC, useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { getLocalAndParse } from "../../store/localStoreUtils";
import { WEB_APP_URL } from "../../constants/modal";

import QrCodeMobile from "../QrcodeMobile";
import QrCodeDesktop from "../QrcodeDesktop";
import MeasuredBy from "../molecules/MeasuredBy";

import { useTranslation } from "react-i18next";

import { recommend, scanCheckMeasurements } from "../../api/endpoints";
import { capitalizeFirstLetter } from "../../utils";
import { handleAnalytics } from "../../utils/tracking";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

interface IPropsStepQRCode {
  step: any;
  pid?: string | null;
  gender?: string;
  skipToResult: () => void;
  setRecommendedSize: (value: any) => void;
  titleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

const StepQRCode: FC<IPropsStepQRCode> = ({
  step,
  pid,
  gender,
  skipToResult,
  setRecommendedSize,
  titleStyles,
  subtitleStyles,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation(
    isMobile ? "components/qrcode/mobile" : "components/qrcode/desktop"
  );

  const [WebAppURL, setWebAppURL] = useState("");
  const [checkCount, setCheckCount] = useState(1);
  const user = getLocalAndParse("user");
  const [storedUid, setStoredUid] = useState(
    localStorage.getItem("uid") || undefined
  );
  const [storedMid, setStoredMid] = useState(
    localStorage.getItem("mid") || undefined
  );

  const urlParameters = new URLSearchParams(window.location.search);
  const product_id = urlParameters.get("product_id");
  const lang = urlParameters.get("lang")?.split("-")[0].toLowerCase();
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const setStoredData = () => {
    const storedMidRes = localStorage.getItem("mid") || "";
    const storedUidRes = localStorage.getItem("uid") || "";
    setStoredUid(storedUidRes);
    setStoredMid(storedMidRes);

    setWebAppURL(
      `${WEB_APP_URL}?uid=${storedUidRes}&mid=${storedMidRes}&product_id=${pid}&gender=${
        user?.gender ?? "male"
      }&age=${user?.age ?? 40}&height=${user?.height ?? 170}&weight=${
        user?.weight ?? 70
      }&lang=${lang}`
    );
  };

  useEffect(() => {
    if (checkCount > 0) {
      setStoredData();

      scanCheckMeasurements()
        .then((value: any) => {
          if (value) {
            setCheckCount(0);
            recommend(product_id).then((result: any) => {
              if (result) {
                setRecommendedSize(result);
                skipToResult();
              }
            });
          } else {
            setTimeout(() => {
              setCheckCount((prev) => prev + 1);
            }, 500);
          }
        })
        .catch((error) => {
          console.error("Error: scanCheckMeasurements failed -", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCount, storedUid, storedMid, product_id]);

  // useEffect(() => {
  //   handleAnalytics("step", "bodyscan", {
  //     key: "step",
  //     type: "STR",
  //     value: "3_BODYSCAN",
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <div
        className="main-qr-container"
        style={{ minHeight: isMobile ? "20vh" : "79vh" }}
      >
        <div className={isMobile ? "text-block-mobile" : "text-block"}>
          <span
            className="title-scan"
            style={{
              ...(isMobile ? titleStyles : subtitleStyles),
              marginTop: isMobile ? "10px" : 0,
              textTransform:
                (isMobile ? titleStyles : subtitleStyles).textTransform ===
                "capitalize"
                  ? "none"
                  : ((isMobile ? titleStyles : subtitleStyles)
                      .textTransform as React.CSSProperties["textTransform"]),
              fontSize:
                !isMobile && brandDefined?.name === "SRP"
                  ? "18px"
                  : (isMobile ? titleStyles : subtitleStyles).fontSize,
            }}
          >
            {(isMobile ? titleStyles : subtitleStyles).textTransform ===
            "capitalize"
              ? capitalizeFirstLetter(t("title"))
              : t("title")}
          </span>
          <span
            className="description-scan"
            style={{
              ...subtitleStyles,
              textTransform:
                subtitleStyles.textTransform === "capitalize"
                  ? "none"
                  : (subtitleStyles.textTransform as React.CSSProperties["textTransform"]),
              fontSize:
                !isMobile && brandDefined?.name === "SRP"
                  ? "18px"
                  : (isMobile ? titleStyles : subtitleStyles).fontSize,
            }}
          >
            {subtitleStyles.textTransform === "capitalize"
              ? capitalizeFirstLetter(t("description"))
              : t("description")}
          </span>
        </div>
        {isMobile ? (
          <QrCodeMobile
            gender={gender || user?.gender}
            loading={!WebAppURL}
            url={WebAppURL}
            subtitleStyles={subtitleStyles}
          />
        ) : (
          <QrCodeDesktop loading={!WebAppURL} url={WebAppURL} />
        )}
      </div>
      <MeasuredBy step={step} />
    </>
  );
};

export default StepQRCode;
