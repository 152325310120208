import { SvgIcon, SvgIconProps } from "@mui/material";

const CheckIconUnavailable = (props: SvgIconProps) => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3127_3701)">
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
        fill="#272626"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27131 3.48697C9.40956 3.63626 9.40956 3.87831 9.27131 4.0276L5.11746 8.51303C4.97921 8.66232 4.75506 8.66232 4.6168 8.51303L2.72869 6.4742C2.59044 6.32491 2.59044 6.08286 2.72869 5.93357C2.86694 5.78428 3.0911 5.78428 3.22935 5.93357L4.86713 7.70209L8.77065 3.48697C8.9089 3.33768 9.13306 3.33768 9.27131 3.48697Z"
        fill="#272626"
        fillOpacity="0.32"
      />
    </g>
    <defs>
      <clipPath id="clip0_3127_3701">
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CheckIconUnavailable;
