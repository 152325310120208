import { FC, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Stepper from "../molecules/Stepper";
import BellyChoice from "../BellyChoice";
import MeasuredBy from "../molecules/MeasuredBy";

import { GENDERS, MODAL_STEPS } from "../../constants/modal";

import { useTranslation } from "react-i18next";

import belly_1_female from "../../assets/questionnaire/belly/belly-1-female.svg";
import belly_2_female from "../../assets/questionnaire/belly/belly-2-female.svg";
import belly_3_female from "../../assets/questionnaire/belly/belly-3-female.svg";

import belly_1_male from "../../assets/questionnaire/belly/belly-1-male.svg";
import belly_2_male from "../../assets/questionnaire/belly/belly-2-male.svg";
import belly_3_male from "../../assets/questionnaire/belly/belly-3-male.svg";

import PhotoCameraIcon from "../../assets/icons/PhotoCameraIcon";

import {
  titleStyles,
  disabledStylesContinue,
  activeStylesContinue,
  scanCTAStyle,
} from "../../configs";
import {
  capitalizeFirstLetter,
  isEmptyCTAStyle,
  useKeyNavigation,
} from "../../utils";
import { newMeasure } from "../../api/endpoints";
import { handleAnalytics } from "../../utils/tracking";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

interface IPropsStepBelly {
  step: any;
  gender?: string;
  nextStep: () => void;
  setStep: (
    value: React.SetStateAction<{
      number: number;
    }>
  ) => void;
  setPreviousStep: React.Dispatch<
    React.SetStateAction<{
      number: number;
    } | null>
  >;
  value: {
    current: number;
  };
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

const StepBelly: FC<IPropsStepBelly> = ({
  step,
  gender,
  nextStep,
  setStep,
  setPreviousStep,
  value,
  subtitleStyles,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [selected, setSelected] = useState<number | undefined>(value.current);
  const { t } = useTranslation("components/belly");

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const scanTextTransform =
    scanCTAStyle.textTransform as React.CSSProperties["textTransform"];

  const continueButtonStyles = !selected
    ? disabledStylesContinue
    : activeStylesContinue;

  // useEffect(() => {
  //   if (selected) {
  //     handleAnalytics("action", "belly_selected", {
  //       key: "shape",
  //       type: "INT",
  //       value: selected,
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selected]);

  // useEffect(() => {
  //   handleAnalytics("step", "belly", {
  //     key: "step",
  //     type: "STR",
  //     value: "4_BELLY",
  //   });

  //   if (gender) {
  //     handleAnalytics("step", "belly", {
  //       key: "gender",
  //       type: "STR",
  //       value: gender,
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [gender]);

  useKeyNavigation({
    selected,
    setSelected,
    valueRef: value,
    onEnter: nextStep,
  });

  return (
    <>
      <div
        className="belly-description"
        style={{
          ...subtitleStyles,
          textAlign: titleStyles.textAlign as React.CSSProperties["textAlign"],
          justifyContent:
            titleStyles.textAlign as React.CSSProperties["justifyContent"],
          color: titleStyles.color as React.CSSProperties["color"],
          textTransform:
            subtitleStyles.textTransform === "capitalize"
              ? "none"
              : (subtitleStyles.textTransform as React.CSSProperties["textTransform"]),
        }}
      >
        <p>
          {subtitleStyles.textTransform === "capitalize"
            ? capitalizeFirstLetter(t("description"))
            : t("description")}
        </p>
      </div>
      <div
        className="belly"
        style={{
          marginTop: isMobile ? "20px" : "40px",
          gap: isMobile ? "10px" : "30px",
        }}
      >
        <div className="body">
          <BellyChoice
            image={gender === GENDERS.F ? belly_1_female : belly_1_male}
            onClick={() => {
              value.current = 1;
              setSelected(1);
            }}
            text={t("size.one")}
            isSelected={selected === 1}
            gender={gender}
            type="belly"
          />
          <BellyChoice
            image={gender === GENDERS.F ? belly_2_female : belly_2_male}
            onClick={() => {
              value.current = 2;
              setSelected(2);
            }}
            text={t("size.two")}
            isSelected={selected === 2}
            gender={gender}
            type="belly"
          />
          <BellyChoice
            image={gender === GENDERS.F ? belly_3_female : belly_3_male}
            onClick={() => {
              value.current = 3;
              setSelected(3);
            }}
            text={t("size.three")}
            isSelected={selected === 3}
            gender={gender}
            type="belly"
          />
        </div>
        <div className="controls">
          {brandDefined?.name !== "SRP" && (
            <Stepper stepsNum={gender === GENDERS.M ? 3 : 2} step={1} key={1} />
          )}
          <button
            type="button"
            disabled={!selected}
            className="continue-button step-belly-button"
            onClick={() => nextStep()}
            style={{
              ...continueButtonStyles,
              marginTop: "20px",
              textTransform:
                continueButtonStyles.textTransform === "capitalize"
                  ? "none"
                  : continueButtonStyles.textTransform,
            }}
          >
            {continueButtonStyles.textTransform === "capitalize"
              ? capitalizeFirstLetter(t("continue", { ns: "components/intro" }))
              : t("continue", { ns: "components/intro" })}
          </button>
          {!!scanCTAStyle && !isEmptyCTAStyle(scanCTAStyle) && (
            <div
              style={{
                display: "flex",
                border: `1px solid ${scanCTAStyle.borderColor}`,
                borderWidth: scanCTAStyle.borderWidth,
                borderRadius: scanCTAStyle.borderRadius,
                borderColor: scanCTAStyle.borderColor,
                color: scanCTAStyle.fontColor,
                fontSize: scanCTAStyle.fontSize,
                fontWeight: scanCTAStyle.fontWeight,
                textTransform:
                  scanTextTransform === "capitalize"
                    ? "none"
                    : scanTextTransform,
                marginTop: isMobile ? "10px" : "0",
                width: "calc(100% - 3px)",
                height: "auto",
                maxHeight: "48px",
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                justifyContent: "center",
                padding: isMobile ? "11px 0" : "12px 0",
              }}
              className="scan-CTA-button"
              onClick={async () => {
                const resultNewMeasure = await newMeasure("scan");
                if (resultNewMeasure) {
                  setStep(MODAL_STEPS.QR_CODE);
                  setPreviousStep(MODAL_STEPS.BELLY);
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  textDecoration:
                    scanCTAStyle.fontStyle === "underline"
                      ? "none"
                      : scanCTAStyle.fontStyle,
                  alignItems: "center",
                }}
              >
                <PhotoCameraIcon fill={scanCTAStyle.fontColor} />
                <span
                  style={{
                    textAlign: isMobile ? "left" : "center",
                    fontSize: scanCTAStyle.fontSize,
                  }}
                  className={`${
                    scanCTAStyle.fontStyle === "underline"
                      ? "scan-text underline"
                      : ""
                  }`}
                >
                  {scanTextTransform === "capitalize"
                    ? capitalizeFirstLetter(t("scan"))
                    : t("scan")}
                </span>
              </div>
            </div>
          )}
        </div>
        <MeasuredBy step={step} />
      </div>
    </>
  );
};

export default StepBelly;
