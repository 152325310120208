import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { font, welcomeScreenUI } from "../../../../configs";
import { GENDERS } from "../../../../constants/modal";
import { capitalizeFirstLetter } from "../../../../utils";
import { brandsArray } from "../../../../configs/configLoader";
import { handleAnalytics } from "../../../../utils/tracking";

import "./index.css";

interface GenderBlockProps {
  selectedGender: string;
  handleGenderSelect: (newUnit: string) => void;
  nextStep: () => void;
  nextStepDesktop: () => void;
  disableContinue: boolean;
}

type GenderButtonType = "focused" | "unfocused";

const GenderBlock: React.FC<GenderBlockProps> = ({
  selectedGender,
  handleGenderSelect,
  nextStep,
  nextStepDesktop,
  disableContinue,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isLargeHeightRelative = useMediaQuery({
    maxHeight: 778,
    minWidth: 767,
  });

  const { t } = useTranslation("components/intro");

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const getButtonsStyles = (type: GenderButtonType) => {
    const defaultButtonsStyles = {
      backgroundColor: welcomeScreenUI.genderCTA[type].backgroundColor,
      borderRadius: welcomeScreenUI.genderCTA[type].borderRadius,
      borderColor: welcomeScreenUI.genderCTA[type].borderColor,
      borderWidth: welcomeScreenUI.genderCTA[type].borderWidth,
      fontSize: welcomeScreenUI.genderCTA[type].fontSize,
      fontWeight: welcomeScreenUI.genderCTA[type].fontWeight,
      textTransform:
        brandDefined?.name === "Lacoste" && isMobile
          ? "uppercase"
          : (welcomeScreenUI.genderCTA[type]
              .textTransform as React.CSSProperties["textTransform"]),
      color: welcomeScreenUI.genderCTA[type].fontColor,
      fontFamily: isMobile
        ? `${font}, sans-serif`
        : `${font}, sans-serif !important`,
    };

    return defaultButtonsStyles;
  };

  const femaleButtonStyles =
    selectedGender === GENDERS.F
      ? getButtonsStyles("focused")
      : getButtonsStyles("unfocused");

  const maleButtonStyles =
    selectedGender === GENDERS.M
      ? getButtonsStyles("focused")
      : getButtonsStyles("unfocused");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.key === "Tab") {
      e.preventDefault();

      const selectGender = !selectedGender
        ? GENDERS.F
        : selectedGender === GENDERS.F
        ? GENDERS.M
        : GENDERS.M;

      handleGenderSelect(selectGender);

      if (selectedGender === GENDERS.M) {
        const heightInput = document.querySelector(
          "#height"
        ) as HTMLInputElement | null;
        if (heightInput) {
          heightInput?.focus();
        } else {
          console.error("Element with id 'height' not found.");
        }
      }
    }

    if (e.key === "Enter") {
      if (!disableContinue) {
        nextStepDesktop();

        const elem = document.activeElement as HTMLElement;
        elem?.blur();
      }
    }
  };

  const handleFocus = () => {
    handleGenderSelect(GENDERS.F);
  };

  return (
    <div
      className="gender-buttons"
      style={{
        marginBottom:
          isLargeHeightRelative && brandDefined?.name !== "Sporty & Rich"
            ? 0
            : "30px",
      }}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      tabIndex={0}
    >
      <button
        type="button"
        className={selectedGender === GENDERS.F ? "selected" : ""}
        style={{
          ...femaleButtonStyles,
          textTransform:
            femaleButtonStyles.textTransform === "capitalize"
              ? "none"
              : femaleButtonStyles.textTransform,
        }}
        onClick={() => {
          handleGenderSelect(GENDERS.F);
          if (isMobile) nextStep();
          // handleAnalytics("action", "gender", {
          //   key: "value",
          //   type: "STR",
          //   value: GENDERS.F,
          // });
        }}
      >
        {femaleButtonStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("gender.female"))
          : t("gender.female")}
      </button>
      <button
        type="button"
        className={selectedGender === GENDERS.M ? "selected" : ""}
        style={{
          ...maleButtonStyles,
          textTransform:
            maleButtonStyles.textTransform === "capitalize"
              ? "none"
              : maleButtonStyles.textTransform,
        }}
        onClick={() => {
          handleGenderSelect(GENDERS.M);
          if (isMobile) nextStep();

          // handleAnalytics("action", "gender", {
          //   key: "value",
          //   type: "STR",
          //   value: GENDERS.M,
          // });
        }}
      >
        {maleButtonStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("gender.male"))
          : t("gender.male")}
      </button>
    </div>
  );
};

export default GenderBlock;
