import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import GenderBlock from "./components/GenderBlock";
import UserInfoBlock from "./components/UserInfoBlock";
import MeasuredBy from "../molecules/MeasuredBy";

import { MODAL_STEPS } from "../../constants/modal";
import {
  activeStylesContinue,
  disabledStylesContinue,
  uxGender,
  welcomeScreenUI,
  titleStyles as titleStylesBase,
} from "../../configs";
import { capitalizeFirstLetter } from "../../utils";
import { brandsArray } from "../../configs/configLoader";
import { handleAnalytics } from "../../utils/tracking";

import "./index.css";

interface StepIntroDesktopProps {
  step: any;
  error: any;
  height: string;
  unit: string;
  feet: string;
  inches: string;
  unitWeight: string;
  weight?: string | undefined;
  age: string;
  selectedGender: string;
  disableContinue: boolean;
  handleFieldChange: (e: any, type: string, blurSignal: string) => void;
  handleUnitChange: (newUnit: string) => void;
  handleUnitWeightChange: (newUnit: string) => void;
  handleGenderSelect: (newUnit: string) => void;
  setNeedValidate: React.Dispatch<React.SetStateAction<boolean>>;
  nextStepGender: () => void;
  nextStep: () => void;
  clearMid: () => void;
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

const StepIntroDesktop: React.FC<StepIntroDesktopProps> = ({
  step,
  error,
  height,
  unit,
  feet,
  inches,
  unitWeight,
  weight,
  age,
  selectedGender,
  disableContinue,
  handleFieldChange,
  handleUnitChange,
  handleUnitWeightChange,
  handleGenderSelect,
  setNeedValidate,
  nextStepGender,
  nextStep,
  clearMid,
  subtitleStyles,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const isLargeHeightRelative = useMediaQuery({
    maxHeight: 778,
    minWidth: 767,
  });

  const isMediumHeightRelative = useMediaQuery({
    maxHeight: 678,
    minWidth: 767,
  });

  const isSmallHeightRelative = useMediaQuery({
    maxHeight: 620,
    minWidth: 767,
  });

  const { t } = useTranslation("components/intro");

  const infoRef = useRef<HTMLDivElement>(null);

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const isMobileSRP = isMobile && brandDefined?.name === "SRP";

  const titleTextTransform = welcomeScreenUI.input_fields.title
    .textTransform as React.CSSProperties["textTransform"];

  const continueButtonStyles = disableContinue
    ? disabledStylesContinue
    : activeStylesContinue;

  const isSportyAndRich = brandDefined?.name === "Sporty & Rich";

  const titlesStyles = {
    fontWeight: welcomeScreenUI.input_fields.title.fontWeight,
    textAlign: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleTextTransform === "capitalize" ? "none" : titleTextTransform,
    color: welcomeScreenUI.input_fields.title
      .fontColor as React.CSSProperties["color"],
    fontSize:
      (isLargeHeightRelative && !isSportyAndRich) ||
      (isMediumHeightRelative && isSportyAndRich)
        ? `calc(${welcomeScreenUI.input_fields.title.fontSize} - 2px)`
        : isMobile
        ? "14px"
        : (welcomeScreenUI.input_fields.title
            .fontSize as React.CSSProperties["fontSize"]),
    justifyContent: welcomeScreenUI.input_fields.title
      .textAlign as React.CSSProperties["justifyContent"],
  };

  const descriptionStyles = {
    ...subtitleStyles,
    fontSize:
      (isLargeHeightRelative && !isSportyAndRich) ||
      (isMediumHeightRelative && isSportyAndRich)
        ? `calc(${subtitleStyles.fontSize} - 2px)`
        : subtitleStyles.fontSize,
    textTransform:
      subtitleStyles.textTransform === "capitalize"
        ? "none"
        : subtitleStyles.textTransform,
    marginTop:
      (isLargeHeightRelative && !isSportyAndRich) ||
      (isMediumHeightRelative && isSportyAndRich)
        ? "20px"
        : !isMobile
        ? "35px"
        : 0,
    marginBottom:
      (isLargeHeightRelative && !isSportyAndRich) ||
      (isMediumHeightRelative && isSportyAndRich)
        ? "20px"
        : !isMobile
        ? "40px"
        : "30px",
  };

  useEffect(() => {
    clearMid();

    const heightInput = document.querySelector(
      "#height"
    ) as HTMLInputElement | null;
    if (heightInput) {
      heightInput?.focus();
    } else {
      console.error("Element with id 'height' not found.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (step?.number !== MODAL_STEPS.GENDER.number) {
  //     handleAnalytics("step", "welcome", {
  //       key: "step",
  //       type: "STR",
  //       value: "1_WELCOME",
  //     });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [step]);

  return (
    <>
      {!isMobile && (
        <span className="description" style={descriptionStyles}>
          {subtitleStyles.textTransform === "capitalize"
            ? capitalizeFirstLetter(t("description"))
            : t("description")}
        </span>
      )}
      {((isMobile && step?.number === MODAL_STEPS.GENDER.number) ||
        isMobileSRP) && (
        <span className="descriptionSmall" style={descriptionStyles}>
          {(descriptionStyles.textTransform as string) === "capitalize"
            ? capitalizeFirstLetter(
                t(brandDefined?.name === "SRP" ? "description" : "gender.title")
              )
            : t(brandDefined?.name === "SRP" ? "description" : "gender.title")}
        </span>
      )}
      {isMobileSRP && step?.number === MODAL_STEPS.GENDER.number && (
        <span className="descriptionSmall" style={titlesStyles}>
          {(descriptionStyles.textTransform as string) === "capitalize"
            ? capitalizeFirstLetter(t("gender.title"))
            : t("gender.title")}
        </span>
      )}
      <form
        style={{
          marginTop: isMobile ? "30px" : 0,
          gap:
            (isMediumHeightRelative && !isSportyAndRich) ||
            (isSmallHeightRelative && isSportyAndRich)
              ? "5px"
              : "15px",
        }}
      >
        {(!isMobile ||
          (isMobile && step?.number === MODAL_STEPS.INTRO_MOBILE.number)) && (
          <UserInfoBlock
            error={error}
            height={height}
            unit={unit}
            feet={feet}
            inches={inches}
            unitWeight={unitWeight}
            weight={weight}
            age={age}
            handleFieldChange={handleFieldChange}
            handleUnitChange={handleUnitChange}
            handleUnitWeightChange={handleUnitWeightChange}
            setNeedValidate={setNeedValidate}
            titlesStyles={titlesStyles}
            disableContinue={disableContinue}
            nextStep={nextStep}
          />
        )}
        <div className="gender-block">
          {!uxGender && (
            <>
              {!isMobile && (
                <label
                  htmlFor="gender"
                  style={{
                    ...titlesStyles,
                    textTransform:
                      titleStylesBase.textTransform === "capitalize"
                        ? "none"
                        : (titleStylesBase.textTransform as React.CSSProperties["textTransform"]),
                  }}
                >
                  {titleStylesBase.textTransform === "capitalize"
                    ? capitalizeFirstLetter(t("gender.title"))
                    : t("gender.title")}
                </label>
              )}
              {step.number !== MODAL_STEPS.INTRO_MOBILE.number && (
                <GenderBlock
                  selectedGender={selectedGender}
                  handleGenderSelect={handleGenderSelect}
                  nextStep={nextStepGender}
                  nextStepDesktop={nextStep}
                  disableContinue={disableContinue}
                />
              )}
            </>
          )}
        </div>

        {!(isMobile && step?.number === MODAL_STEPS.GENDER.number) && (
          <button
            type="button"
            disabled={disableContinue}
            className="continue-button"
            onClick={() => {
              if (!disableContinue) {
                nextStep();
                // handleAnalytics("action", "continue", {
                //   key: "current_step",
                //   type: "STR",
                //   value: "1_WELCOME",
                // });
              }
            }}
            style={{
              ...continueButtonStyles,
              textTransform:
                continueButtonStyles.textTransform === "capitalize"
                  ? "none"
                  : continueButtonStyles.textTransform,
              marginBottom: !isMobile ? "60px" : 0,
              position: "relative",
              bottom: isMobileSRP ? "22px" : 0,
            }}
          >
            {continueButtonStyles.textTransform === "capitalize"
              ? capitalizeFirstLetter(t("continue"))
              : t("continue")}
          </button>
        )}
      </form>
      <MeasuredBy step={step} />
    </>
  );
};

export default StepIntroDesktop;
