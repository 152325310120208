import { SvgIcon, SvgIconProps } from "@mui/material";

const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3170_41060)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 1.9585C4.66295 1.9585 1.95801 4.66344 1.95801 8.00016C1.95801 11.3369 4.66295 14.0418 7.99967 14.0418C11.3364 14.0418 14.0413 11.3369 14.0413 8.00016C14.0413 4.66344 11.3364 1.9585 7.99967 1.9585ZM0.708008 8.00016C0.708008 3.97309 3.9726 0.708496 7.99967 0.708496C12.0268 0.708496 15.2913 3.97309 15.2913 8.00016C15.2913 12.0272 12.0268 15.2918 7.99967 15.2918C3.9726 15.2918 0.708008 12.0272 0.708008 8.00016Z"
        fill={props?.fill || "#856400"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 7.37516C8.34485 7.37516 8.62467 7.65498 8.62467 8.00016V10.6668C8.62467 11.012 8.34485 11.2918 7.99967 11.2918C7.6545 11.2918 7.37467 11.012 7.37467 10.6668V8.00016C7.37467 7.65498 7.6545 7.37516 7.99967 7.37516Z"
        fill={props?.fill || "#856400"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.37467 5.3335C7.37467 4.98832 7.6545 4.7085 7.99967 4.7085H8.00634C8.35152 4.7085 8.63134 4.98832 8.63134 5.3335C8.63134 5.67867 8.35152 5.9585 8.00634 5.9585H7.99967C7.6545 5.9585 7.37467 5.67867 7.37467 5.3335Z"
        fill={props?.fill || "#856400"}
      />
    </g>
    <defs>
      <clipPath id="clip0_3170_41060">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default InfoIcon;
