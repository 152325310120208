import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import MeasuredBy from "../molecules/MeasuredBy";

import ArrowSelectIcon from "../../icons/ArrowSelectIcon";
import PhotoCameraIcon from "../../assets/icons/PhotoCameraIcon";
import HelpIcon from "../../assets/icons/HelpIcon";

import { choiceScreenUI, font, titleStyles } from "../../configs";
import { handleAnalytics } from "../../utils/tracking";

import "./index.css";

interface StepDeviceSelectProps {
  step: any;
  nextPhotoStep: () => void;
  nextQuestionsStep: () => void;
}

const StepDeviceSelect: React.FC<StepDeviceSelectProps> = ({
  step,
  nextPhotoStep,
  nextQuestionsStep,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation("components/device_select");

  const [hoveredBlock, setHoveredBlock] = useState<
    "questions" | "photo" | null
  >(null);
  const [activeBlock, setActiveBlock] = useState<"questions" | "photo" | null>(
    null
  );

  const desktopStyles = !isMobile
    ? {
        marginTop: "100px",
        gap: "20px",
      }
    : undefined;

  const handleContainerStyles = (type: "questions" | "photo") => {
    const borderColor =
      activeBlock === type
        ? choiceScreenUI.routeCTA.fontColor
        : !activeBlock && !hoveredBlock
        ? choiceScreenUI.routeCTA.borderColor
        : "";

    return {
      backgroundColor: !activeBlock && !hoveredBlock ? "#FFFFFF" : "",
      borderRadius: isMobile
        ? 0
        : !activeBlock && !hoveredBlock
        ? choiceScreenUI.routeCTA.borderRadius
        : "",
      border:
        !activeBlock && !hoveredBlock
          ? `${choiceScreenUI.routeCTA.borderWidth} solid ${borderColor}`
          : "",
      borderColor,
      color:
        !activeBlock && !hoveredBlock ? choiceScreenUI.routeCTA.fontColor : "",
      outline: "none",
    };
  };

  const fontFamily = `${font}, sans-serif`;

  const handleBlockClick = (type: "questions" | "photo") => {
    if (type === "questions") nextQuestionsStep();
    else nextPhotoStep();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    let handledType = "questions" as "questions" | "photo";
    if (activeBlock === "questions") {
      handledType = "photo";
    }
    if (activeBlock === "photo") {
      handledType = "questions";
    }

    if (e.key === "Tab") {
      e.preventDefault();

      setActiveBlock(handledType);
    }

    if (e.key === "Enter" && activeBlock) {
      handleBlockClick(activeBlock);
    }
  };

  const handleFocus = () => setActiveBlock("questions");

  // useEffect(() => {
  //   handleAnalytics("step", "choice", {
  //     key: "current_step",
  //     type: "STR",
  //     value: "2_CHOICE",
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Box
        className="main-container"
        style={desktopStyles}
        onFocus={handleFocus}
      >
        <Box
          className="button-container"
          tabIndex={0}
          role="button"
          onClick={() => handleBlockClick("questions")}
          style={handleContainerStyles("questions")}
          onMouseEnter={() => setHoveredBlock("questions")}
          onMouseLeave={() => setHoveredBlock(null)}
          onMouseDown={() => setActiveBlock("questions")}
          onMouseUp={() => setActiveBlock(null)}
          onKeyDown={handleKeyDown}
        >
          <Box className="parts-container">
            <Box className="title-container">
              <Box className="icon-text">
                <HelpIcon fill={titleStyles.color} />
                <Typography className="title" style={{ fontFamily }}>
                  {t("titleQuestions")}
                </Typography>
              </Box>
              <Typography className="title2" style={{ fontFamily }}>
                {t("descriptionQuestions")}
              </Typography>
            </Box>
            <ArrowSelectIcon />
          </Box>
        </Box>
        <Box
          className="button-container"
          tabIndex={0}
          role="button"
          style={handleContainerStyles("photo")}
          onClick={() => handleBlockClick("photo")}
          onMouseEnter={() => setHoveredBlock("photo")}
          onMouseLeave={() => setHoveredBlock(null)}
          onMouseDown={() => setActiveBlock("photo")}
          onMouseUp={() => setActiveBlock(null)}
          onKeyDown={handleKeyDown}
        >
          <Box className="parts-container">
            <Box className="title-container">
              <Box className="icon-text">
                <PhotoCameraIcon fill={titleStyles.color} />
                <Typography className="title" style={{ fontFamily }}>
                  {t("titleScan")}
                </Typography>
              </Box>
              <Typography className="title2" style={{ fontFamily }}>
                {t("descriptionScan")}
              </Typography>
            </Box>
            <ArrowSelectIcon />
          </Box>
        </Box>
      </Box>
      <MeasuredBy step={step} />
    </>
  );
};

export default StepDeviceSelect;
