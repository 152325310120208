import { FC } from "react";

import { morphoUIStyles } from "../../../configs";

import "./index.css";

interface IPropsStepper {
  stepsNum: number;
  step: number;
}

const Stepper: FC<IPropsStepper> = ({ stepsNum, step }) => {
  const stepRender = (stepNum: number, step: number) => {
    const steps = [];

    for (let i = 0; i < stepNum; i += 1)
      steps.push(
        <div
          key={i}
          className={`step ${i + 1 === step ? "step_active" : undefined}`}
          style={{
            backgroundColor:
              i + 1 === step
                ? morphoUIStyles.stepper.focused.color
                : morphoUIStyles.stepper.unfocused.color,
          }}
        />
      );

    return steps;
  };

  return <div className="stepper">{stepRender(stepsNum, step)}</div>;
};

export default Stepper;
